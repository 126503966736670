import React from 'react';
import { Divider, Typography, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { Box } from '@mui/material';
import DesktopSalesRecordsFilter from './SalesRecordsFilter/DesktopSalesRecordsFilter';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import {
  GetBrandOrderProductItemsExcelDocument,
  GetOrderLicenseNameField,
  GetOrderProductPeriodField,
  HubAssetOutput,
  Language_Code,
  LanguageCodeEnum,
  OrderLicenseType,
  OrderProductItemOutput,
  useGetBrandOrderProductItemsQuery,
  useMyAssetsQuery,
} from '../../generated/graphql';
import { useDebounce } from 'react-use';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import { HowToShowSummary, PeriodButton } from './SalesRecords.types';
import SalesRecordsList from './SalesRecordsList/SalesRecordsList';
import SalesRecordsSummary from './SalesRecordsSummary/SalesRecordsSummary';
import SalesRecordsLoading from './SalesRecordsLoading/SalesRecordsLoading';
import SalesRecordsFooter from './SalesRecordsFooter/SalesRecordsFooter';
import MobileSalesRecordsFilter from './SalesRecordsFilter/MobileSalesRecordsFilter';
import { useApolloClient } from '@apollo/client';
import { DesktopSalesRecordsFilterProps, MobileSalesRecordsFilterProps } from './SalesRecordsFilter/SalesRecordsFilter.types';

dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

export default function SalesRecordsPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const theme = useDesignSystemTheme();

  const client = useApolloClient();

  const { current: today } = React.useRef(dayjs());
  const { current: initialStartDay } = React.useRef(today.subtract(1, 'month').add(1, 'day').set('hour', 0).set('minute', 0).set('second', 0));
  const { current: initialEndDay } = React.useRef(today.set('hour', 23).set('minute', 59).set('second', 59));
  const keywordInputRef = React.useRef<HTMLInputElement>(null);

  const [keyword, setKeyword] = React.useState('');
  const [debouncedKeyword, setDebouncedKeyword] = React.useState('');
  const [asset, setAsset] = React.useState<HubAssetOutput | null>(null);
  const [currentAssetIdx, setCurrentAssetIdx] = React.useState<number>(0);
  const [keywordAnchor, setKeywordAnchor] = React.useState<HTMLElement | null>(null);
  const [periodField, setPeriodField] = React.useState<GetOrderProductPeriodField>(GetOrderProductPeriodField.CreatedAt);
  const [selectedPeriodButton, setSelectedPeriodButton] = React.useState<PeriodButton>('manual');
  const [startDay, setStartDay] = React.useState<Dayjs | null>(initialStartDay);
  const [endDay, setEndDay] = React.useState<Dayjs | null>(initialEndDay);
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const [isRefunded, setIsRefunded] = React.useState<boolean | null>(null);
  const [howToShowSummary, setHowToShowSummary] = React.useState<HowToShowSummary>('all');
  const [isFree, setIsFree] = React.useState<boolean | null>(null);
  const [licenseType, setLicenseType] = React.useState<OrderLicenseType | null>(null);
  const [condition, setCondition] = React.useState<GetOrderLicenseNameField>(GetOrderLicenseNameField.Author);
  const [conditionKeyword, setConditionKeyword] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [excelLoading, setExcelLoading] = React.useState(false);
  const [doRefetch, setDoRefetch] = React.useState(false);

  const isPeriodChanged = Boolean(!startDay?.isSame(initialStartDay, 'day') || !endDay?.isSame(initialEndDay, 'day') || periodField !== GetOrderProductPeriodField.CreatedAt);

  const {
    data: orderProductItemsData,
    loading: orderProductItemsLoading,
    refetch,
  } = useGetBrandOrderProductItemsQuery({
    variables: {
      pagination: {
        limit: 10,
        page: 1,
      },
      condition: {
        order: {
          period: {
            field: GetOrderProductPeriodField.CreatedAt,
            since: initialStartDay.toISOString(),
            until: initialEndDay.toISOString(),
          },
        },
      },
      lang: language as LanguageCodeEnum,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const productItems: OrderProductItemOutput[] = orderProductItemsData?.getBrandOrderProductItems?.data || [];

  const { data: myAssetData } = useMyAssetsQuery({
    variables: {
      language: language.toUpperCase() as Language_Code,
      title: debouncedKeyword,
      isOnPromotion: null,
      includeFree: true,
      includeIrregularPrice: true,
    },
    skip: !debouncedKeyword,
    fetchPolicy: 'no-cache',
  });

  const myAssets = myAssetData?.myAssets || [];

  async function handleExcelDownloadButtonClick() {
    try {
      setExcelLoading(true);
      const { data } = await client.query({
        query: GetBrandOrderProductItemsExcelDocument,
        variables: {
          lang: language as LanguageCodeEnum,
          condition: {
            ...(asset && {
              asset: {
                assetId: asset.id,
              },
            }),
            order: {
              period: {
                field: periodField,
                since: startDay?.toISOString(),
                until: endDay?.toISOString(),
              },
              ...((licenseType || conditionKeyword) && {
                license: {
                  ...(licenseType && { type: licenseType }),
                  ...(conditionKeyword && {
                    field: condition,
                    name: conditionKeyword,
                  }),
                },
              }),
            },
            ...(isFree !== null && { isFree }),
            ...(isRefunded !== null && { isRefunded }),
          },
        },
      });
      const url = data.getBrandOrderProductItemsExcel;
      const a = document.createElement('a');
      a.href = url;
      document.body.appendChild(a);
      a.click();
      a.remove();
      setExcelLoading(false);
    } catch (e) {
      console.error(e, 'Error occurred while downloading excel file');
    }
  }

  function handleDesktopKeywordChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (keywordAnchor === null) setKeywordAnchor(keywordInputRef.current);
    setKeyword(event.target.value);
  }

  function handleMobileKeywordChange(event: React.ChangeEvent<HTMLInputElement>) {
    setKeyword(event.target.value);
  }

  function handleKeydown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.nativeEvent.isComposing) return;
    const assetsLength = myAssets.length > 5 ? 5 : myAssets.length;
    if (event.key === 'ArrowDown' && myAssets.length > 0) {
      setCurrentAssetIdx((idx) => (idx + 1) % assetsLength);
    }
    if (event.key === 'ArrowUp' && myAssets.length > 0) {
      event.preventDefault();
      setCurrentAssetIdx((idx) => (idx - 1 + assetsLength) % assetsLength);
    }
    if (event.key === 'Enter' && myAssets.length > 0) {
      const currentAsset = myAssets[currentAssetIdx];
      makeDesktopAssetClickHandler(currentAsset)();
    }
  }

  function handleKeywordReset() {
    setKeyword('');
    setAsset(null);
  }

  function handleAssetMenuClose() {
    if (asset === null) setKeyword('');
    else setKeyword(asset.title);
    setKeywordAnchor(null);
    setCurrentAssetIdx(0);
  }

  function handlePeriodTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPeriodField(event.target.value as GetOrderProductPeriodField);
  }

  function handlePeriodTypeReset() {
    setPeriodField(GetOrderProductPeriodField.CreatedAt);
  }

  function handleToggleDatePicker() {
    setShowDatePicker((p) => !p);
  }

  function handleSelectStartDay(value: Dayjs | null) {
    if (value === null) setStartDay(null);
    else setStartDay(value.set('hour', 0).set('minute', 0).set('second', 0));
  }

  function handleSelectEndDay(value: Dayjs | null) {
    if (value === null) setEndDay(null);
    else setEndDay(value.set('hour', 23).set('minute', 59).set('second', 59));
  }

  function handleConditionKeywordChange(event: React.ChangeEvent<HTMLInputElement>) {
    setConditionKeyword(event.target.value);
  }

  function handleConditionKeywordReset() {
    setConditionKeyword('');
  }

  async function handleApplyButtonClick() {
    switch (isRefunded) {
      case null:
        setHowToShowSummary('all');
        break;
      case true:
        setHowToShowSummary('refunded');
        break;
      case false:
        setHowToShowSummary('sold');
        break;
    }
    setPage(1);
    setDoRefetch(true);
  }

  function handleResetButtonClick() {
    setAsset(null);
    setKeyword('');
    setPeriodField(GetOrderProductPeriodField.CreatedAt);
    setSelectedPeriodButton('one-month');
    setStartDay(initialStartDay);
    setEndDay(initialEndDay);
    setIsRefunded(null);
    setIsFree(null);
    setLicenseType(null);
    setCondition(GetOrderLicenseNameField.Author);
    setConditionKeyword('');
    setPage(1);
    setDoRefetch(true);
  }

  function handlePageChange(_event: React.ChangeEvent<unknown>, page: number) {
    setPage(page);
    setDoRefetch(true);
  }

  function makeDesktopAssetClickHandler(asset: HubAssetOutput) {
    return () => {
      setAsset(asset);
      setKeyword(asset.title);
      setKeywordAnchor(null);
      setCurrentAssetIdx(0);
    };
  }

  function makeMobileAssetClickHandler(asset: HubAssetOutput) {
    return () => {
      setAsset(asset);
      setKeyword(asset.title);
    };
  }
  function makePeriodButtonClickHandler(value: PeriodButton) {
    return () => {
      setSelectedPeriodButton(value);
      switch (value) {
        case 'one-month':
          handleSelectStartDay(initialEndDay.subtract(1, 'month').add(1, 'day'));
          handleSelectEndDay(initialEndDay);
          break;
        case 'three-months':
          handleSelectStartDay(initialEndDay.subtract(3, 'month').add(1, 'day'));
          handleSelectEndDay(initialEndDay);
          break;
        case 'six-months':
          handleSelectStartDay(initialEndDay.subtract(6, 'month').add(1, 'day'));
          handleSelectEndDay(initialEndDay);
          break;
        case 'entire':
          handleSelectStartDay(dayjs().set('year', 2000).set('month', 0).set('date', 1));
          handleSelectEndDay(initialEndDay);
          break;
        case 'manual':
          break;
      }
    };
  }

  function makeSellingStatusButtonClickHandler(value: boolean | null) {
    return () => setIsRefunded(value);
  }

  function makePaymentTypeButtonClickHandler(value: boolean | null) {
    return () => setIsFree(value);
  }

  function makeLicenseTypeButtonClickHandler(value: OrderLicenseType | null) {
    return () => setLicenseType(value);
  }

  function makeConditionButtonClickHandler(value: GetOrderLicenseNameField) {
    return () => setCondition(value);
  }

  const desktopFilterProps = {
    // ref
    keywordInputRef,
    // state
    keyword,
    asset,
    currentAssetIdx,
    keywordAnchor,
    periodField,
    selectedPeriodButton,
    startDay,
    endDay,
    showDatePicker,
    isRefunded,
    isFree,
    licenseType,
    condition,
    conditionKeyword,
    loading: orderProductItemsLoading,
    // query result
    myAssets,
    // event handler
    onKeywordChange: handleDesktopKeywordChange,
    onKeydown: handleKeydown,
    onKeywordReset: handleKeywordReset,
    onAssetMenuClose: handleAssetMenuClose,
    onPeriodTypeChange: handlePeriodTypeChange,
    onToggleDatePicker: handleToggleDatePicker,
    onSelectStartDay: handleSelectStartDay,
    onSelectEndDay: handleSelectEndDay,
    onConditionKeywordChange: handleConditionKeywordChange,
    onConditionKeywordReset: handleConditionKeywordReset,
    onApplyButtonClick: handleApplyButtonClick,
    onResetButtonClick: handleResetButtonClick,
    // event handler factory
    makeAssetClickHandler: makeDesktopAssetClickHandler,
    makePeriodButtonClickHandler,
    makeSellingStatusButtonClickHandler,
    makePaymentTypeButtonClickHandler,
    makeLicenseTypeButtonClickHandler,
    makeConditionButtonClickHandler,
  } satisfies DesktopSalesRecordsFilterProps;

  const mobileFilterProps = {
    // state
    keyword,
    asset,
    currentAssetIdx,
    periodField,
    selectedPeriodButton,
    startDay,
    endDay,
    isRefunded,
    isFree,
    licenseType,
    condition,
    conditionKeyword,
    loading: orderProductItemsLoading,
    // query result
    myAssets,
    // event handler
    onKeywordChange: handleMobileKeywordChange,
    onKeywordReset: handleKeywordReset,
    onPeriodTypeChange: handlePeriodTypeChange,
    onPeriodTypeReset: handlePeriodTypeReset,
    onSelectStartDay: handleSelectStartDay,
    onSelectEndDay: handleSelectEndDay,
    onConditionKeywordChange: handleConditionKeywordChange,
    onConditionKeywordReset: handleConditionKeywordReset,
    onApplyButtonClick: handleApplyButtonClick,
    // event handler factory
    makeAssetClickHandler: makeMobileAssetClickHandler,
    makePeriodButtonClickHandler,
    makeSellingStatusButtonClickHandler,
    makePaymentTypeButtonClickHandler,
    makeLicenseTypeButtonClickHandler,
    makeConditionButtonClickHandler,
    // etc
    isPeriodChanged,
  } satisfies MobileSalesRecordsFilterProps;

  // state을 update한 이후에 쿼리를 실행하기 위해
  // useEffect로 doRefetch를 감지하여 refetch를 실행
  // 설명:
  // 1. filter와 관련된 state가 변경된다 (doRefetch도 이 때 true로 변경한다)
  // 2. 새롭게 변경된 filter state가 적용된 컴포넌트가 렌더된다
  // 3. useEffect가 실행되어 doRefetch가 true인 상태이므로 refetch를 실행한다
  React.useEffect(() => {
    if (doRefetch) {
      refetch({
        pagination: {
          limit: 10,
          page,
        },
        condition: {
          ...(asset && {
            asset: {
              assetId: asset.id,
            },
          }),
          order: {
            period: {
              field: periodField,
              since: startDay?.toISOString(),
              until: endDay?.toISOString(),
            },
            ...((licenseType || conditionKeyword) && {
              license: {
                ...(licenseType && { type: licenseType }),
                ...(conditionKeyword && {
                  field: condition,
                  name: conditionKeyword,
                }),
              },
            }),
          },
          ...(isFree !== null && { isFree }),
          ...(isRefunded !== null && { isRefunded }),
        },
        lang: language as LanguageCodeEnum,
      });
      setDoRefetch(false);
    }
  }, [doRefetch]);

  // keyword가 바뀔 때마다 myAssets 검색하는 것을 막기 위해 디바운스 처리
  useDebounce(
    () => {
      setDebouncedKeyword(keyword);
    },
    300,
    [keyword],
  );

  return (
    <>
      <Box
        sx={{
          padding: '40px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          [theme.breakpoints.down('largeTablet')]: {
            padding: 0,
            gap: 0,
          },
        }}
      >
        {/*  title  */}
        <Box
          sx={{
            [theme.breakpoints.down('largeTablet')]: {
              backgroundColor: theme.palette['color/white'],
              padding: '40px 16px 16px 16px',
            },
          }}
        >
          <Typography
            variant={'typography/title/medium/bold'}
            sx={{
              [theme.breakpoints.down('largeTablet')]: {
                fontSize: '20px',
                lineHeight: '28px',
              },
            }}
          >
            {t('salesRecordsPage.title')}
          </Typography>
        </Box>
        {/*  contents  */}
        <Box
          sx={{
            display: 'flex',
            gap: '24px',
            width: '100%',
            maxWidth: 'calc(100vw - 80px)',
            [theme.breakpoints.down('largeTablet')]: {
              flexDirection: 'column',
              maxWidth: '100vw',
              gap: 0,
            },
          }}
        >
          {/*  filter  */}
          <DesktopSalesRecordsFilter {...desktopFilterProps} />
          <MobileSalesRecordsFilter {...mobileFilterProps} />
          {/*  right  */}
          <Box
            sx={{
              borderRadius: '16px',
              backgroundColor: theme.palette['color/white'],
              padding: '24px',
              width: '100%',
              height: 'fit-content',
              [theme.breakpoints.down('largeTablet')]: {
                borderRadius: 0,
                padding: 0,
                backgroundColor: 'unset',
              },
            }}
          >
            <SalesRecordsSummary loading={orderProductItemsLoading} sellingStatus={howToShowSummary} data={orderProductItemsData} />
            <Divider
              sx={{
                marginTop: '8px',
                [theme.breakpoints.down('largeTablet')]: {
                  display: 'none',
                },
              }}
            />
            {orderProductItemsLoading && <SalesRecordsLoading />}
            {!orderProductItemsLoading && <SalesRecordsList productItems={productItems} />}
            <Divider
              sx={{
                marginTop: '24px',
                [theme.breakpoints.down('largeTablet')]: {
                  display: 'none',
                },
              }}
            />
            <SalesRecordsFooter
              itemsLoading={orderProductItemsLoading}
              data={orderProductItemsData}
              page={page}
              onPageChange={handlePageChange}
              onExcelDownloadButtonClick={handleExcelDownloadButtonClick}
              excelLoading={excelLoading}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
