import React from 'react';
import { Button, Skeleton, Typography, Stack } from '@mui/material';
import { FieldsBox, FieldGroup, FieldName, FieldValue } from './styled';
import { UserFormType } from './type';
import { LanguageCodeEnum, useGetPartnerPlanQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'api/index';
import { PLAN_TYPE } from './value/PlanType';

type Props = UserFormType & {
  onClickShowDialog: () => void;
};

export default function StoreForm({ userId, onClickShowDialog }: Props) {
  const { t, i18n } = useTranslation();
  const { data, loading } = useGetPartnerPlanQuery();

  if (loading || !data) {
    return <Skeleton />;
  }

  const handleDownload = () => {
    if (!data?.termAgree?.file) return;
    downloadFile(data.termAgree.file.id, data.termAgree.file.fileName);
  };

  return (
    <>
      <FieldsBox>
        <FieldGroup>
          <FieldName>{t('user.brandNumber')}</FieldName>
          <FieldValue>{data.me.brand?.godoBrandCd ? data.me.brand?.godoBrandCd : '-'}</FieldValue>
        </FieldGroup>
        <FieldGroup>
          <FieldName>{t('user.projectReference')}</FieldName>
          <Stack gap={0.5}>
            {data.me.reference.map((x, i) => (
              <FieldValue key={`project_url_${i}`}>
                <u>
                  <a href={x.url} target={'_blank'} style={{ color: 'inherit' }}>
                    {x.url}
                  </a>
                </u>
              </FieldValue>
            ))}
          </Stack>
        </FieldGroup>
        {i18n.language === LanguageCodeEnum.Ko && (
          <FieldGroup>
            <FieldName>파트너십 유형</FieldName>
            <FieldValue>
              {data.partnerPlan?.type
                ? PLAN_TYPE({
                    type: data.partnerPlan?.type,
                    isExclusive: data.partnerPlan?.isExclusive,
                    t,
                  })
                : t('plan.notSelected')}
            </FieldValue>
          </FieldGroup>
        )}
        <FieldGroup>
          <FieldName>{t('user.fee')}</FieldName>
          <FieldValue>
            {data.partnerPlan
              ? `${data.partnerPlan.commissionRate}% ${data.partnerPlan.commissionRate === 0 && i18n.language !== LanguageCodeEnum.Ko ? `(Early Entry)` : ``}`
              : '-'}
          </FieldValue>
        </FieldGroup>
        {data?.termAgree?.file && (
          <FieldGroup>
            <FieldName>{t('user.contract')}</FieldName>
            <FieldValue>
              <Stack spacing={1} pt={0.5}>
                <Button onClick={handleDownload} variant="outlined" size="medium" sx={{ width: 'fit-content' }}>
                  {t('download')}
                </Button>
                <Typography fontSize={12} lineHeight={'18px'} fontWeight={400}>
                  {t('user.downloadDescription')}
                </Typography>
              </Stack>
            </FieldValue>
          </FieldGroup>
        )}
      </FieldsBox>
      <Stack alignItems={'end'} mt={1.5}>
        <Button variant="text" onClick={onClickShowDialog}>
          {t('user.editInfo')}
        </Button>
      </Stack>
    </>
  );
}
